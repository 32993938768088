#myVideo {
    width: 100% ! important;
    height: 100%   !important;
    object-fit: fill;
position:absolute;


}
#gearbox {
  display: flex;
  justify-content: space-between;
}
#leftgear, #rightgear {
  width: 20vw;
  max-width: 20%;
  height: auto;
}
.cursor {
  width: 40px;
  height: 40px;
  border: 2px solid #fefefe;
  border-radius: 100%;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
}



.Logo1{
  width: 10em;
  margin-top: 5em;
  margin-left: 8em;
  color: #F5F5F5;
  
  filter:   saturate(400%)  brightness(1000%) ;
}

.shubham{
  margin-top: -1em;
    margin-right: 90em;
}
.menubar {
  display: inline-block;
  cursor: pointer;
}

.bar1 {
  width: 25px;
  height: 2px;
  background-color: #F5F5F5;
  margin: 0px 6px;
  transition: 0.5s;
align-content: flex-end;
}
.bar2{
  width: 25px;
  height: 2px;
  background-color:#F5F5F5;
  margin: 0 6px;
  transition: 0.8s;
  margin-top: 2px;
}
.bar3{
  width: 15px;
  height: 2px;
  background-color:#F5F5F5;
  margin: 0 16px;
  transition: 0.4s;
  margin-top: 2px;
  text-align: end;
}
/* .On-video:hover{
  color: #00F;
} */
.header{
    transform: translate3d(0px, 0px, 0px);
    height: var(--header-normal);
    z-index: 1;
    content: "";
    position: absolute;
    /* top: -1.5rem;
    left: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem; */
    /* position: fixed; */
    top: -2em;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;
}
.navbar{

    display: flex;
    /* align-items: flex-end; */
    /* justify-content: right; */
    /* align-items: flex-end; */
    pointer-events: all;
    content: "";
    position: absolute;
    top: 3rem;
    left: 0rem;
    right: -1.5rem;
    bottom: -1.5rem;
    position: relative; 
    z-index: 2;
    font-family:'Open Sans', sans-serif;;
    font: 14.5549px ; 
}
.menu{
    position: relative;
    display: flex;
    /* justify-content: flex-end;
    align-items: flex-end; */
    left: -1.5rem;
  }

  .About{
    position: relative;
    display: block;
    margin-right: 3rem;
    font-family: messina-sans;
   
    overflow: hidden;
    z-index: 2;
    color: #fff;
    
    
  }


  a ,a:focus,a:active{
    color: #FFF;
    text-decoration: none 
  }
  a:hover {
     color: #00F;
     text-decoration: underline;
     
    
  
  }
  .On-video{
    transform: matrix(1, 0, 0, 1, 0, 0);
    z-index: 2;
    position: absolute;
    cursor: pointer;
    margin-top: 20%;
    color: white;
    margin-left: -30%;
    display: inline-block;
    text-align: start;
    line-height: 1.3;
    font-weight: 200;
    font-size: 5rem;
    font-family: "Lucida Console", "Courier New", monospace;
    /* position: relative; */
  }
  .start-content{
    display: flex;
    /* font-family: "Lucida Console", "Courier New", monospace; */
    font-family:"Lucida Console", "Courier New", monospace;
  }
  .start-content2{
      margin-top: -0.4em;
    font-family:"Lucida Console", "Courier New", monospace;
  }

  .row{
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .image{
    /* transform: translate(90px, 50% ); */
    /* transform: translate3d(5px, 723px, 0px); */
    /* margin-top: 10%;
    background-image: url(../Assets/images/mountainWhite.jpg) */
    background-image: url(../Assets/background2.jpg) ;
    position: absolute;
    top: 107%;
    margin-top: 20em;
    left: 12%;
    min-width: 80%;
     min-height: 100%;
     /* max-height: 100%; */
     /* margin-right: 30%; */
     /* background-size: 100% cover!important; */
     background-size: 100% 120% ;
    background-position: 40%;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    object-fit: contain;
    margin: auto;
    /* z-index: 1 */
  }

  .image-text{
    position: relative;
  }
  .main{
    display: block;
  }

  .image-text2{
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
        "content"
        "img1"
        "img2";
    z-index: 2;


  }
  .image2{
    width: 38%;
    min-height: 30%;
    margin-top: -10em;
    margin-left: 5%;
    box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.3); 
    
  }
  .image1{
    width: 37%;
    height: 50%;
    margin-left: 48%;
    margin-top: 60%;
    box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.3); 
  }

  .image-text3{
    position: relative;
    grid-area: content;
    padding-bottom: 4.5rem;
    padding-bottom: var(--spacing-s);

  }
  /* .image2{
    width: 50%;
    height: 70%;
  } */
  .content-title{
    margin-top: -46%;
    position: relative;
    margin-right: 50em;
  }
  .c-line{
    margin-top: -17.6em;
    /* margin-bottom: 2rem; */
    margin-left: -1em;
    line-height: 1;
    -webkit-transform-origin: left;
    transform-origin: left;
    font-family:'Open Sans', sans-serif;;
    font-style: italic;
    font-size: 1.5em;
    color: #000000;
  }
  .c-line2{
    margin-left: -1.5em;
    font-family:FreeMono, monospace;
    font-style: oblique;
    font-size: 1.5em;
    color: #696969;
  }
  .style{
    display: block;
    text-align: start;
    position: absolute;
    line-height: 2.5em;
    opacity: 1;

    margin-left: 10em;
    margin-top: 1%;
  }
  .headline1{
    /* --value-small-1: 5%;
      --value-small-2: calc(var(--value-small-1)*-1);
      --value-medium-1: 10%;
      --value-medium-2: calc(var(--value-medium-1)*-1);
      --value-big-1: 17%;
      --value-big-2: calc(var(--value-big-1)*-1); */
      font-family:Optima, sans-serif;
      white-space: nowrap;
      opacity: 1;
      transform: matrix(1, 0, 0, 1, 0, 2.9);
      font-style: italic;
      font-size: 1.1em;
    
      /* font-family: gt-sectra; */
      margin-top: 10%;
      margin-left: -65%;
  }
  
  .headline2{
    /* --value-small-1: 5%;
    --value-small-2: calc(var(--value-small-1)*-1);
    --value-medium-1: 10%;
    --value-medium-2: calc(var(--value-medium-1)*-1);
    --value-big-1: 17%;
    --value-big-2: calc(var(--value-big-1)*-1); */
    font-family:FreeMono, monospace;
    font-style: oblique;
      font-size: 1.1em;
      color: #696969;
    white-space: nowrap;
    opacity: 1;
      transform: matrix(1, 0, 0, 1, 0, -2.6);
      font-family: gotham;
      margin-left: -63%;
    
  }
  
  .para{
    display: block;
    text-align: start;
    position: relative;
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
    margin-left: 10%;
    font-family: messina-sans;
    line-height: 1.6;
      font-size: 1rem;
  }
  

  .figure{
    position: relative;
      padding-top:5%;
      position: absolute;
      left: 0;
      width: 95%;
      z-index: 2;
      /* padding-left: 1%; */
  }
  
  .cards{
    width: 30%;
    height: 50%;
    margin-left: -20%;
    margin-bottom: 40%;
    box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.3); 
  }
  
  .card2{
    width: 30%;
    height: 50%;
    margin-left: 0.8%;
    margin-bottom: 40%;
    box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.3); 
    z-index: 2;
  }
  .card3{
    width: 30%;
    height: 40%;
    margin-left: 71.5%;

    margin-top: -80em;
    box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.3); 
    margin-bottom: 40%;
  }
  .card4{
    margin-top: -1.4em;
  }

  .location{
    content: "";
      position: absolute;
      top: 45%;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 250%;
      margin-top: 130%;
      background-color:#F5F5F5;
  }
  .figure{
    position: relative;
      padding-top:5%;
      position: absolute;
      left: 0;
      width: 95%;
      z-index: 2;
      /* padding-left: 1%; */
  }
  
  .logo{
    width: 7%;
    margin-top: 10%;
    margin-left: 100%;
    animation: rotation 8s infinite linear;
     
     
  }
  .cards{
    width: 30%;
    height: 50%;
    margin-left: -20%;
    margin-bottom: 40%;
    box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.3); 
  }
  
  .card2{
    width: 30%;
    height: 50%;
    margin-left: 0.8%;
    margin-bottom: 40%;
    box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.3); 
    z-index: 2;
  }
  .card3{
    width: 30%;
    height: 40%;
    margin-left: 71.5%;

    margin-top: -80em;
    box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.3); 
    margin-bottom: 40%;
  }
  .card4{
    margin-top: -1.4em;
   
  }
  .logo{
      margin-left: -68em;
  }
 
  .button{
    /* opacity: .25; */
      /* pointer-events: none; */
      /* padding-left: 3%; */
      /* padding-right: 2rem; */
      margin-left: 2%;
      /* padding-top: 50%; */
      margin-top: 35%;
      /* display: inline-flex; */
      /* padding: 5px; */
      /* background-color: transparent;  */
      /* color: #000000; */
      /* z-index: 2; */
  
  }
  .button:hover ,button:focus {
    background: rgb(119, 118, 118);
  }
  .button1{
    transform: translateY(50%);
      /* padding-right: 1rem; */
      display: flex;
      align-items: center;
      position: relative;
      margin-left: 10%;
      margin-top: 5%;
      color: #000000;
      transition: opacity .5s ease;
      z-index: 2;
      /* background-color: black; */
  }
  .svg{
   /* color: #000000; */
   background-color:transparent; 

  }
  .arrow1{
    color: #000000;
  }
  .line{
    z-index: 2; 
    margin-top: 36%;
    margin-left: 1%;
  }

  .header2{
    margin-top: -9em;
    margin-left: -31em;
  }
  .lands{
    opacity: 1;
      transform: matrix(1, 0, 0, 1, 0, 2.9);
      white-space: nowrap;
      display: block;
    /* z-index: 2;  */
    margin-top: 25%;
    margin-left: 4%;
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-size: 90%;
    
  }
  .discovery{
    transform: matrix(1, 0, 0, 1, 0, 2.9);
    font-size: 100%;
    white-space: nowrap;
    display: block;
    font-family: sans-serif;
    font-weight: -50%;
      font-display: initial;
      z-index: 2;
      color: #696969;
      /* margin-top: 10%; */
      margin-left: 5%;
  }
  .para2{
    display: block;
    text-align: start;
    position: relative;
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
    font-family: messina-sans;
    line-height: 1.6;
      font-size: 1rem;
      margin-left: 3%;
      color: #696969;
  }

  .sentence{
    margin-top: 2%;
    margin-left: 5.2em;
  }
  .navbar2{
    /* display: none; */
    margin-top: 2%;
    /* margin-top: 120%; */
    z-index: 2;
    transform: matrix(1, 0, 0, 1, 0, 0);
    display: flex;
    justify-content: space-between;
    left: 35rem;
    right: -1.5rem;
    bottom: -1.5rem;
  }
  .nav-dropdown{
    /* margin-top: 120%; */
    z-index: 2;
  margin-left: 10em;
    right: -1.5rem;
    bottom: -1.5rem;
  }
  .next{
    margin-left: -1em;
    position: relative;
  }
  .next22{
   margin-right: 50em;
    position: relative;
  }
  .clouds{
    /* background-image: url(../Assets/images/earth-clouds.png); */
    position: absolute;
    z-index: 2;
    transform: matrix(1, 0, 0, 1, 0, 0);
    margin-top: 10%;
    margin-left: -10%;
  }
  .earth{
    width: 450%;
    margin-left: -27em;
    z-index: -1;
  }
  .map-box{
    /* display: flex; */
      /* flex-wrap: wrap; */
      /* justify-content: space-between; */
      /* position: relative; */
      /* padding: 20vw; */
      /* padding: var(--gutter); */
      /* background-color: #161f1e;
      transform: matrix(1, 0, 0, 1, 0, 0); 
      /* margin-left: 50%;
      margin-top: -50%; */
      /* width: 50%;
      max-height: 200%;*/
      /* bottom: 40px;
    right: 80px; */
      /* padding-left: 20px;
    padding-right: 20px; */
    /* padding: 30%;  */
    /* width: 50%;
    height: 100%; */
    /* flex-wrap: wrap; */
    position: relative;
    /* padding: 30%; */
    margin-left: 60%;
    bottom: 60px;
    right: 70px;

   
    color: white;
    width: 20%;
    transform: matrix(1, 0, 0, 1, 0, 0); 
    z-index: 2;
    height: 30%;
  }
  .text-block {
    position: absolute;
    bottom: 1160px;
    right: 70px;
    background-color: black;
    color: white;
    padding: 15%;
    width: 40%;
    height: 20%;
    padding-left: 5%;
    padding-right: 10%;
    margin-left: -82%;
    top: 0em;
    padding-bottom: -70%;
    left: 127%;
    z-index:3;
    margin-top: 185em;

  }

  .swimming-deck{
    /* width: 150%; */
    /* height: 850%; */
    max-width: 40%;
  height: 33em;
    margin-left: 10%;
    /* height: 1000%; */
    /* padding: 10%; */
    /* padding-top: -700%; */
    margin-top: -30%;
    /* margin-right: 40%; */
    position: absolute;
  }


  .suntop{
    margin-left: -30em;
    
  }
  .speed{
    margin-left: -20em;
    margin-top: -2em;
  }
  .compas{

    margin-left: -10em;
    margin-top: -2em;
  }
  .name{
    margin-top: 6em;
    margin-left: -16em;
  }
  .maplocation{
    margin-top: 4em;
    margin-left: -35em;
  }
  .value{
    margin-top: -3em;
    margin-left: 15em;
    color: #c0bbbb;
  }
  .Background1{

    width: 60%;
    margin-left: 20em;
    height: 40em;
    left: 0em;
   position: absolute;
    margin: 80px auto;
     background-image: url(https://discoverylandco.com/static/globe-clouds.png) ;
    
    background-repeat: no-repeat;  
    animation: demo1 linear 20s infinite;
    z-index: 2;
    opacity: 0.2;
    
  }
  @keyframes demo1 {
    from{
      background-position: 20% 0px;
    
    }
    to{
      background-position:100% 0px;
    }
  }
  .Background2{

    width: 68%;
    margin-left: 10em;
    height: 14em;
    left: 0em;
   position: absolute;
    margin: 620px auto;
     background-image: url(https://discoverylandco.com/static/globe-clouds.png) ;

    background-repeat: no-repeat;  
    animation: demo2 linear 25s infinite;
    z-index: 2;
    opacity: 0.2;
    
  }
  @keyframes demo2 {
    from{
      background-position: 25% 0px;
    
    }
    to{
      background-position:100% 0px;
    }
  }

  .img1{
    width: 29em;
    margin-top: 52.8em;
    margin-left: -28em;
    height: 45em;
    filter: blur(1px);  
    -webkit-filter: blur(1px);
  
  }
  .img2{
    width: 29em;
    margin-top: -65em;
    margin-left: -1em;
    height: 45em;
    filter: blur(1px);  
    -webkit-filter: blur(1px);
  
  }
  .img3{
    width: 29em;
    top: -80em;
    margin-left: 55.3em;
    height: 45em;
    filter: blur(1px);  
    -webkit-filter: blur(1px);
  
  }
  .img4{
    margin-top: -45.2em;
  }

.Exp{
  margin-top: -18em;
  margin-left: -40em;
  font-size: 1.4em;
  color: #F5F5F5;
  
  filter:   saturate(400%)  brightness(1000%) ;
}
.Exp1{
  margin-top: -4em;
  margin-left: -1em;
  font-size: 1.4em;
  color: #a08686;
  
  filter:   saturate(400%)  brightness(1000%) ;
 
}
.Exp2{
  margin-top: -4em;
  margin-left: 40em;
  font-size: 1.4em;
  color: #a08686;
  
  filter:   saturate(400%)  brightness(1000%) ;

}
@keyframes hang {
  0% { transform: translateY(0); }
  100% { transform: translateY(-40px); }
}
@-webkit-keyframes hang {
  0% { transform: translateY(0); }
  100% { transform: translateY(-20px); }
}


  .footer{
    -webkit-animation: hang 2s infinite  alternate;
    animation: hang 3s infinite  alternate;
    position: absolute;
      background-color: #161f1e;
      /* transform: translateY(-0.167411px) translate3d(0px, 0px, 0px); */
      /* z-index: 2; */
      content: "";
      /* position: relative; */
      
      padding: 5%;
      width: 90%;
      height: 50%;
      margin-top: 17em;
      z-index: -1;
      
  }

  .last-left-content{
    margin-left: 53%;
    margin-top: -8%;
    color: #fff;
    line-height:200%;
    align-items: flex-start;
    text-align: start;
  
  
  }
  .last-left-content1{
    margin-left: 50%;
    margin-top: -1%;
    color: #fff;
    line-height:200%;
    align-items: flex-start;
    text-align: start;
  
  
  }

  .last-content{
    color: #ad7c7c;
    margin-left: 60%;
    margin-top: -11%;
    line-height:200%
  }
  .last-content2{
    color: #F5F5F5;
    margin-left: 80%;
    margin-top: -13%;
    line-height:200%
  }
  li {
    list-style-type: none;
}
.LogoF{
  margin-top: -10em;
  width: 2em;
}
.LogoF1{
  width: 35em;
  color: #F5F5F5;
  
  filter:   saturate(400%)  brightness(1000%) ;
}

@media only screen and (max-width: 768px) {
  img {
    width: 200%;
  height: 200px;
  object-fit: cover;
  object-position: bottom;
  }
  #myVideo{
    width: 400% ! important;
    height: 300%   !important;
    object-fit: fill;
position:absolute;
    
  }
  .image-text{
    position: relative;
  }
  .image1{
    margin-top: 150em;
    width: 70em;
  }
  .image2{
margin-top: 10em;
width: 90em;
height: 50em;
margin-left: 0em;
  }
  .image-text3{
    margin-top: 1em;
    margin-left: 0em;
   
  }
  .content-title{

    font-size: 4em;
  }
  .style{

    width: 10em;
    
  }
}
